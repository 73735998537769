import { init } from "echarts";

let seriesItemConfig = {
  completed: {
    text: "已完成",
    color: "#5470c6"
  },
  waitToStart: {
    text: "等待开始",
    color: "#fac858"
  },
  inProgress: {
    text: "正在进行",
    color: "#91cc75"
  },
  saved: {
    text: "暂存退出",
    color: "#73c0de"
  },
  cancelled: {
    text: "放弃答题",
    color: "#bebebe"
  }
};

let testCaseStatChart = null;

export function drawTestCaseStatChart(
  chartDOM,
  {
    chartTitle,
    chartSubtext,
    dateSeries,
    visualMarkData,
    tooltipPosition,
    tooltipCallback
  }
) {
  testCaseStatChart = init(chartDOM);
  let chartOptions = {
    title: {
      text: chartTitle,
      subtext: chartSubtext
    },
    grid: {
      top: 80,
      left: 100
    },
    toolbox: {
      show: true,
      right: 40,
      feature: {
        saveAsImage: {
          title: "保存为图片"
        }
      }
    },
    tooltip: {
      trigger: "axis",
      triggerOn: "mousemove",
      alwaysShowContent: false,
      axisPointer: {
        type: "shadow"
      },
      position: tooltipPosition,
      formatter: tooltipCallback
    },
    xAxis: {
      type: "value"
    },
    yAxis: {
      type: "category",
      axisLabel: {
        width: 90,
        overflow: "break"
      },
      data: dateSeries
    },
    legend: {
      top: 40,
      right: 40,
      data: [
        seriesItemConfig.completed.text,
        seriesItemConfig.waitToStart.text,
        seriesItemConfig.inProgress.text,
        seriesItemConfig.saved.text,
        seriesItemConfig.cancelled.text
      ]
    },
    series: [
      {
        name: "已完成",
        type: "bar",
        stack: "total",
        label: {
          show: true
        },
        emphasis: {
          focus: "series"
        },
        data: visualMarkData.completed,
        itemStyle: {
          color: seriesItemConfig.completed.color
        }
      },
      {
        name: "等待开始",
        type: "bar",
        stack: "total",
        label: {
          show: true
        },
        emphasis: {
          focus: "series"
        },
        data: visualMarkData.waitToStart,
        itemStyle: {
          color: seriesItemConfig.waitToStart.color
        }
      },
      {
        name: "正在进行",
        type: "bar",
        stack: "total",
        label: {
          show: true
        },
        emphasis: {
          focus: "series"
        },
        data: visualMarkData.inProgress,
        itemStyle: {
          color: seriesItemConfig.inProgress.color
        }
      },
      {
        name: "暂存退出",
        type: "bar",
        stack: "total",
        label: {
          show: true
        },
        emphasis: {
          focus: "series"
        },
        data: visualMarkData.saved,
        itemStyle: {
          color: seriesItemConfig.saved.color
        }
      },
      {
        name: "放弃答题",
        type: "bar",
        stack: "total",
        label: {
          show: true
        },
        emphasis: {
          focus: "series"
        },
        data: visualMarkData.cancelled,
        itemStyle: {
          color: seriesItemConfig.cancelled.color
        }
      }
    ]
  };
  testCaseStatChart.setOption(chartOptions);
}

export function disposeChart() {
  // 清空原有图像
  if (testCaseStatChart) {
    testCaseStatChart.dispose();
    testCaseStatChart = null;
  }
}

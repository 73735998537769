import { post } from "@/utils/axios";

export async function groupTestCases(groupGuid, startDate, endDate) {
  let response = await post("/statistic/GroupTestStatistic", {
    testGroupGuid: groupGuid,
    startDate,
    endDate
  });
  return response;
}

export async function deptTestCases(deptGuid, startDate, endDate) {
  let response = await post("/statistic/DepartmentTestStatistic", {
    departmentGuid: deptGuid,
    startDate,
    endDate
  });
  return response;
}

<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat class="mx-4 my-4">
      <v-card-title>测量控制</v-card-title>
      <v-card-text v-html="controlBtnDesc"></v-card-text>
      <v-btn
        class="ml-6"
        x-large
        :color="controlBtnColor"
        @click="isTogglingCanStartTest = true"
      >
        <v-icon class="mr-2">{{ controlBtnIcon }}</v-icon>
        <span>{{ controlBtnName }}</span>
      </v-btn>
    </v-card>
    <v-divider class="my-4"></v-divider>
    <v-card flat class="mx-4 my-4">
      <v-card-title>信息统计</v-card-title>
      <v-card-text class="px-6 stat-chart-div">
        <div class="d-flex align-center justify-space-between mb-4">
          <v-btn-toggle
            v-model="currentChartMode"
            mandatory
            dense
            color="primary"
          >
            <v-btn>测量日期</v-btn>
            <v-btn>量表</v-btn>
          </v-btn-toggle>
          <div class="d-flex align-center">
            <v-select
              class="ml-6"
              dense
              single-line
              hide-details="auto"
              outlined
              label="时间筛选"
              :items="filterDateRangeItems"
              item-text="text"
              item-value="value"
              v-model="dateRangeSelected"
              @change="reloadDeptTestData(guid)"
            ></v-select>
            <AppTooltipBtn
              class="ml-2"
              color="primary"
              icon="mdi-refresh"
              tooltip="刷新图表数据"
              @click="reloadDeptTestData(guid)"
            />
          </div>
        </div>
        <div v-if="!deptTestCaseStatList.length">未找到任何案例</div>
        <div v-else id="echartsdeptstat" class="mt-6"></div>
      </v-card-text>
    </v-card>
    <AppDialog
      v-model="isTogglingCanStartTest"
      size="small"
      :title="`确定要 ${controlBtnName} 吗？`"
      :color="controlBtnColor"
      :action-text="controlBtnName"
      @confirm="toggleCanStartTestDirectly"
    >
      <span>{{ controlBtnDialogDesc }}</span>
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import _ from "lodash";
import { deptTestCases } from "@/api/statistic";
import {
  fetchCanStartTestDirectly,
  saveDeptCanStartTestDirectly
} from "@/api/dept";
import {
  createdDateGroupByFunc,
  presetDateRangeSelects,
  getStartEndDateFromPresetRange
} from "@/utils/dateTime";
import {
  drawTestCaseStatChart,
  disposeChart
} from "@/utils/charts/chartsTestCaseStat";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog,
    AppTooltipBtn
  },

  props: {
    guid: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      canStartTestDirectly: false,
      deptTestCaseStatList: [],
      filterDateRangeItems: presetDateRangeSelects,
      dateRangeSelected: presetDateRangeSelects.find(dr => dr.default).value,
      currentChartMode: 0,
      // dialogs
      isTogglingCanStartTest: false,
      errorMsg: ""
    };
  },

  watch: {
    guid(newGuid) {
      this.dateRangeSelected = presetDateRangeSelects.find(
        dr => dr.default
      ).value;
      this.reloadDeptTestData(newGuid);
      this.reloadCanStartTestDirectly(newGuid);
    },
    currentChartMode() {
      if (this.deptTestCaseStatList.length) {
        disposeChart();
        this.$nextTick(() => {
          this.drawDeptTestStatData();
        });
      }
    }
  },

  computed: {
    controlBtnName() {
      return this.canStartTestDirectly ? "开启统一答题" : "开启自由答题";
    },
    controlBtnIcon() {
      return this.canStartTestDirectly
        ? "mdi-account-supervisor-circle"
        : "mdi-account-circle";
    },
    controlBtnColor() {
      return this.canStartTestDirectly ? "warning" : "success";
    },
    controlBtnDesc() {
      return this.canStartTestDirectly
        ? "目前状态为：自由答题。<br />被试者进入答题界面后，可直接开始答题。"
        : "目前状态为：统一答题。<br />被试者进入答题界面后，无法开始测量；需等待管理员点击“开启自由答题”按钮才能开始答题。";
    },
    controlBtnDialogDesc() {
      return this.canStartTestDirectly
        ? "开启“统一答题”后，被试者进入答题界面后，将无法开始测量，直到再次开启“自由答题”模式。"
        : "开启“自由答题”，被试者进入答题界面可直接开始答题；正在等待答题的被试者，也会直接开始答题。";
    },
    caseGroupedKeys() {
      if (this.deptTestCaseStatList.length) {
        let lodChain = _.chain(this.deptTestCaseStatList);
        if (this.currentChartMode === 0) {
          lodChain = lodChain.groupBy(createdDateGroupByFunc);
        } else if (this.currentChartMode === 1) {
          lodChain = lodChain.groupBy(ca => ca.lbDispName);
        }
        return lodChain.map((_, key) => key).value();
      }
      return [];
    }
  },

  methods: {
    async reloadCanStartTestDirectly(deptGuid) {
      try {
        this.isLoading = true;
        this.canStartTestDirectly = await fetchCanStartTestDirectly(deptGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async reloadDeptTestData(deptGuid) {
      try {
        this.isLoading = true;
        let startEndDate = getStartEndDateFromPresetRange(
          this.dateRangeSelected
        );
        this.deptTestCaseStatList = await deptTestCases(
          deptGuid,
          startEndDate.startDate,
          startEndDate.endDate
        );
        if (this.deptTestCaseStatList.length) {
          disposeChart();
          this.$nextTick(() => {
            this.drawDeptTestStatData();
          });
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async toggleCanStartTestDirectly() {
      this.isTogglingCanStartTest = false;
      try {
        this.isLoading = true;
        await saveDeptCanStartTestDirectly(
          this.guid,
          !this.canStartTestDirectly
        );
        // 提交成功后再修改页面上的值
        this.canStartTestDirectly = !this.canStartTestDirectly;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    getCaseGroupedCount(statusName) {
      if (this.deptTestCaseStatList.length && this.caseGroupedKeys.length) {
        let lodChain = _.chain(this.deptTestCaseStatList).filter({
          status: statusName
        });
        return this.caseGroupedKeys.map(key => {
          let caseWithKey = null;
          if (this.currentChartMode === 0) {
            caseWithKey = lodChain
              .countBy(gtc => gtc.createdDate.includes(key))
              .value().true;
          } else if (this.currentChartMode === 1) {
            caseWithKey = lodChain
              .countBy(gtc => gtc.lbDispName === key)
              .value().true;
          }
          return caseWithKey || "";
        });
      }
      return [];
    },
    // echarts 图表
    drawDeptTestStatData() {
      try {
        drawTestCaseStatChart(document.getElementById("echartsdeptstat"), {
          chartTitle: `${this.name}`,
          chartSubtext: "测量人数统计",
          dateSeries: this.caseGroupedKeys,
          visualMarkData: {
            completed: Object.values(this.getCaseGroupedCount("completed")),
            waitToStart: Object.values(
              this.getCaseGroupedCount("wait_to_start")
            ),
            inProgress: Object.values(this.getCaseGroupedCount("in_progress")),
            saved: Object.values(this.getCaseGroupedCount("saved")),
            cancelled: Object.values(this.getCaseGroupedCount("cancelled"))
          }
        });
      } catch (err) {
        this.errorMsg = err.message;
      }
    }
  },

  created() {
    this.reloadCanStartTestDirectly(this.guid);
    this.reloadDeptTestData(this.guid);
  }
};
</script>

<style lang="scss" scoped>
.stat-chart-div {
  overflow: auto;
  max-width: 650px;
}
#echartsdeptstat {
  width: 600px;
  height: 600px;
}
.filter-item {
  max-width: 400px;
  min-width: 200px;
}
</style>
